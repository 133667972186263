import React from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

function DataTable({ data }) {
  const navigate = useNavigate();

  const handleDetailsClick = (gatewayId) => {
    navigate(`/gateway/${gatewayId}`);
  };

  return (
    <TableContainer component={Paper} elevation={1} style={{ backgroundColor: '#303030' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell component="th" scope="row" style={{ color: 'white' }}>Gateway ID</TableCell>
          <TableCell component="th" scope="row" style={{ color: 'white' }} align='right'>Altitude</TableCell>
          <TableCell component="th" scope="row" style={{ color: 'white' }} align='right'>Antenna Gain</TableCell>
          <TableCell component="th" scope="row" style={{ color: 'white' }} align='right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.gatewayId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{ color: 'white' }}>
                {row.gatewayId}
              </TableCell>
              <TableCell align="center" style={{ color: 'white' }}>{row.altitude}</TableCell>
              <TableCell align="center" style={{ color: 'white' }}>{row.antennaGain}</TableCell>
              <TableCell align="right" style={{ color: 'white' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => handleDetailsClick(row.gatewayId)}
                >
                  Details
                </Button>
                <Button style={{ color: '#1E1E1E' }}
                  variant="contained"
                  color="thingsix"
                  href={`https://app.thingsix.com/gateways/${row.thingsixId}`}
                  target="_blank"
                >
                  View on ThingsIX
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DataTable;
