// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {  
    primary: {
      main: '#1976D2',
    },
    secondary: {
      main: '#D27519', // Replace with your desired color
    },
    thingsix: {
        main: '#4bffb6', // Replace with your desired color
      },
    online: {
        main: 'rgb(0, 194, 0)', // Replace with your desired color
      },
  },
  // You can add more customizations here
});

export default theme;
