import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function SearchBar({ searchTerm, setSearchTerm, handleSearch }) {
  return (
    <div style={{ margin: '20px', display: 'flex', alignItems: 'center' }}>
      <TextField
        id="filled-basic"
        label="Search by wallet address"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
        style={{ marginRight: '10px', flexGrow: 1, color: 'white' }}
        InputLabelProps={{
          style: { color: '#fff' }, // White label
        }}
        InputProps={{
          style: { color: '#fff' }, // White input text
        }}
      />
      <Button variant="contained" onClick={handleSearch}>
        Search
      </Button>
    </div>
  );
}

export default SearchBar;
