import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Header = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: '#1976D2' }}>
      <Toolbar style={{ justifyContent: 'space-between', width: '80%', margin: '0 auto', maxWidth: '800px' }}>
        <Typography variant="h6">
          <Link href="/" style={{ color: 'white', textDecoration: 'none' }}>Hexato - ThingsIX Metrics (beta)</Link>
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link href="https://thingsix-api.hexato.io/docs" target="_blank" style={{ color: 'white', textDecoration: 'none', marginRight: '20px' }}>
            API
          </Link>
          <Link href="https://thingsix-status.hexato.io/status" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
            Status
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
