import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { OnlineStatusIcon, CustomPacketsIcon } from './Icons';
import '../styles/styles.css';
import Grid from '@mui/material/Unstable_Grid2';
import NetworkPieChart from './NetworkPieChart';

function GatewayDetails() {
    const { gatewayId } = useParams();
    const [onlineStatus, setOnlineStatus] = useState();
    const [rxOverall, setRxOverall] = useState();
    const [txOverall, setTxOverall] = useState();
    const [rxMapper, setRxMapper] = useState();
    const [txMapper, setTxMapper] = useState();
    const [networkData, setNetworkData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            // Initialize loading state before fetching
            setOnlineStatus(null);
            setRxOverall(null);
            setTxOverall(null);
            setRxMapper(null);
            setTxMapper(null);
            setNetworkData(null);
            // Make each API call separately, handling each promise individually
            const endpoints = [
                `https://thingsix-api.hexato.io/gateway/online-status?gatewayId=${gatewayId}`,
                `https://thingsix-api.hexato.io/gateway/rx-overall?gatewayId=${gatewayId}`,
                `https://thingsix-api.hexato.io/gateway/tx-overall?gatewayId=${gatewayId}`,
                `https://thingsix-api.hexato.io/gateway/rx-mapper?gatewayId=${gatewayId}`,
                `https://thingsix-api.hexato.io/gateway/tx-mapper?gatewayId=${gatewayId}`,
                `https://thingsix-api.hexato.io/gateway/rx-nwkid?gatewayId=${gatewayId}`
            ];

            Promise.allSettled(endpoints.map(url => axios.get(url))).then(results => {
                results.forEach((result, index) => {
                    if (result.status === 'fulfilled') {
                        switch (index) {
                            case 0:
                                setOnlineStatus(result.value.data.online);
                                break;
                            case 1:
                                setRxOverall(result.value.data.packets);
                                break;
                            case 2:
                                setTxOverall(result.value.data.packets);
                                break;
                            case 3:
                                setRxMapper(result.value.data.packets);
                                break;
                            case 4:
                                setTxMapper(result.value.data.packets);
                                break;
                            case 5:
                                setNetworkData(result.value.data);
                                break;
                            default:
                                console.error('Unexpected case at index:', index);
                                break;
                        }
                    } else {
                        switch (index) {
                            case 0:
                                setOnlineStatus(false); // Assuming onlineStatus is a boolean
                                break;
                            case 1:
                                setRxOverall(null); // Assuming rxOverall is a string or number
                                break;
                            case 2:
                                setTxOverall(null); // Assuming txOverall is a string or number
                                break;
                            case 3:
                                setRxMapper(null); // Assuming rxMapper is a string or number
                                break;
                            case 4:
                                setTxMapper(null); // Assuming txMapper is a string or number
                                break;
                            case 5:
                                setNetworkData(null); // Assuming networkData is an array
                                break;
                            default:
                                console.error('Unexpected case at index:', index);
                                break;
                        }
                        console.error(`Error with request at index ${index}:`, result.reason);
                    }
                });
            });
        };

        fetchData();
    }, [gatewayId]);

    // Helper function to display content or 'No data found'
    const renderContentOrEmptyState = (data, content, noDataContent = <Typography>No data found</Typography>) => {
        if (data === undefined) {
            // Data is still being fetched
            return <Typography>Loading...</Typography>;
        } else if (data === null || (Array.isArray(data) && data.length === 0)) {
            // Data fetching completed but no data was found
            return noDataContent;
        } else {
            // Data is available
            return content;
        }
    };

    return (
        <div className="centered-content">
            <div className="platform">
                <Grid container spacing={2}>
                    {/* Online status - full width */}
                    <Grid item xs={12}>
                        <Paper className="gateway-details-paper-full" elevation={2}>
                            <Typography variant="h5">Gateway Status</Typography>
                            {renderContentOrEmptyState(onlineStatus, (
                                <div>
                                    <OnlineStatusIcon online={onlineStatus} />
                                    <Typography variant="body1">{onlineStatus ? 'Online' : 'Offline'}</Typography>
                                </div>
                            ))}
                        </Paper>
                    </Grid>

                    {/* Other details - two columns on larger screens, one column on smaller screens */}
                    <Grid item xs={12} sm={6}>
                        <Paper className="gateway-details-paper" elevation={2}>
                            <Typography variant="h5">Total RX Packets</Typography>
                            {renderContentOrEmptyState(rxOverall, (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography variant="body1">{rxOverall}</Typography>
                                </div>
                            ), (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography>No data found</Typography>
                                </div>
                            ))}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className="gateway-details-paper" elevation={2}>
                            <Typography variant="h5">Total TX Packets</Typography>
                            {renderContentOrEmptyState(txOverall, (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography variant="body1">{txOverall}</Typography>
                                </div>
                            ), (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography>No data found</Typography>
                                </div>
                            ))}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Paper className="gateway-details-paper" elevation={2}>
                            <Typography variant="h5">Mapper RX Packets</Typography>
                            {renderContentOrEmptyState(rxMapper, (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography variant="body1">{rxMapper}</Typography>
                                </div>
                            ), (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography>No data found</Typography>
                                </div>
                            ))}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Paper className="gateway-details-paper" elevation={2}>
                            <Typography variant="h5">Mapper TX Packets</Typography>
                            {renderContentOrEmptyState(txMapper, (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography variant="body1">{txMapper}</Typography>
                                </div>
                            ), (
                                <div>
                                    <CustomPacketsIcon />
                                    <Typography>No data found</Typography>
                                </div>
                            ))}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className="gateway-details-paper-full" elevation={2}>
                            <Typography variant="h5">Network Data</Typography>
                            {renderContentOrEmptyState(networkData && networkData.length > 0, (
                                <>
                                    <NetworkPieChart data={networkData} />
                                    <Typography>
                                        This chart might not always display the appropriate network name
                                    </Typography>
                                </>
                            ), <Typography variant="body1">Network data not available</Typography>)}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default GatewayDetails;
