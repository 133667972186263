import React from 'react';
import OnlineIcon from '@mui/icons-material/PowerSettingsNew'; // Imported icon for online status
import SensorsIcon from '@mui/icons-material/Sensors'; // Imported icon for packets

export function OnlineStatusIcon({ online }) {
  return <OnlineIcon color={online ? 'online' : 'secondary'} />;
}

export function CustomPacketsIcon() {
  return <SensorsIcon />;
}
