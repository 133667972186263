import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const generateRandomColor = () => {
    let color = '#000000';
    while (color === '#000000') {
        color = '#' + ('000000' + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
    }
    return color;
};



const NetworkPieChart = ({ data }) => {
    // Generate a random color for each data point
    const backgroundColors = data.map(() => generateRandomColor());

    const chartData = {
        labels: data.map(item => item.networkName),
        datasets: [{
            label: 'Packet Count',
            data: data.map(item => item.packets),
            backgroundColor: backgroundColors, // Use the generated colors here
            borderWidth: 1,
        }],
    };

    const options = {
        plugins: {
            legend: {
                position: 'right', // Adjust position as needed
                labels: {
                    color: 'white',
                    boxWidth: 20,
                    padding: 20,
                }
            },
            tooltip: {
                titleFont: {
                    size: 16,
                    weight: 'bold',
                    color: 'white'
                },
                bodyFont: {
                    size: 14,
                    color: 'white'
                }
            }
        }
    };

    return <Pie data={chartData} options={options} />;
};

export default NetworkPieChart;
