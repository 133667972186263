import React, { useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './styles/styles.css';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import DataTable from './components/DataTable';
import GatewayDetails from './components/GatewayDetails';


function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);

  const fetchData = async (ownerAddress) => {
    try {
      const response = await axios.get(`https://thingsix-api.hexato.io/user/gateways?owner=${ownerAddress}`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData([]);
    }
  };

  const handleSearch = () => {
    if (searchTerm) {
      fetchData(searchTerm);
    }
  };

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={
            <div className="centered-content">
              <div className="platform">
                <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} handleSearch={handleSearch} />
                <DataTable data={data} />
              </div>
            </div>
          }/>
          <Route path="/gateway/:gatewayId" element={<GatewayDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;